@import "../base/vars";

//Buttons
.btn {
  border-radius: 60px;
  font-family: gotham-book;
  padding: 6px 0;
  width: 134px;
  font-size: 20px;
}

.btn-primary {
  background-color: #FFFFFF;
  color: #000;
  border-color: #FFFFFF;
}
.btn-primary.btn-primary-white {
  background-color: #FFFFFF;
  color: #fff;
  border-color: #FFFFFF;
}
.btn-primary:hover {
  background-color: #deb88d;
  border-color: #deb88d;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #deb88d;
  border-color: #deb88d;
  box-shadow: none !important;
}

.btn-outline-primary {
  border-color: #FFFFFF;
  color: #FFFFFF;
  box-shadow: none !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #deb88d;
  border-color: #deb88d;
  box-shadow: none !important;
}

.btn-danger {
  background-color: #c62227;
  border-color: #c62227;
  box-shadow: none !important;
}
.btn-danger:hover {
  background-color: #ae1217;
  border-color: #ae1217;
}

.btn-warning {
  color: white;
  background-color: #ffc91c;
  border-color: #ffc91c;
}
.btn-warning:hover {
  background-color: #e5b312;
  color: white;
  border-color: #e5b312;
}

.btn-dark {
  background-color: #000;
}

.btn-link {
  color: blue;
}

.btn-neon-purple {
  background-color: $neon-purple !important;
  color: $color-white !important;
  border: none !important;
  border-radius: 6px !important;
  width: 100%;
  max-width: 230px;
  transition: all 0.2s ease-in-out;
}

.btn-transparent {
  background-color: transparent !important;
  color: $color-white;
  border: 1px solid $color-white !important;
  border-radius: 6px !important;
  width: 100%;
  max-width: 230px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $color-white !important;
    color: $dark-purple !important;
  }
}
