@import "../../../../cryptodata-template-main/base/_vars.scss";
@import "../../../../cryptodata-template-main/base/_helpers.scss";

.confirm-presence-form{
    max-width: 570px;
}

#confirm-presence-form {
   position: relative;
   background-color: transparent;
   // background-image: url("../../../../../assets/PNGs/buy-edain.png");
   // background-position: center;
   // background-size: cover;
   // background-repeat: no-repeat;

   @media screen and (max-width: 1250px) {
      padding-top: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }

   .details-input {
      position: relative;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 8px;
      background: #000000;
      color: #fff;
      padding: 0.5rem 0.3rem;
      overflow: hidden;
      @extend .exo-regular;

      &::placeholder {
         color: #fff;
         @extend .exo-regular;
      }
   }

   .rotate {
      transform: translate(-50%, -50%) rotate(180deg);
   }

   .selector {
      position: relative;
      cursor: pointer;
   }

   .rotate {
      transform: rotate(180deg);
   }


   .error-border {
      border: 1px solid $red-text;
   }
   .no-border {
      border: none;
   }

   .error-text {
      color: $red-text;
   }

   .normal-text {
      color: #fff;
   }
}

@media screen and (max-width: 1068px) {
   #confirm-presence-form {
      .particle-effect-btn-div {
         width: 200px;
      }
   }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}