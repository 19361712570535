@import "../../../../cryptodata-template-main/base/_vars.scss";
@import "../../../../cryptodata-template-main/base/_helpers.scss";

.buy-edain-container {
   position: relative;
   //margin-top: 8rem;
}

  .buy-edain-container {
      position: relative;
      width: 570px;
      max-width: 600px;
      border-radius: 24px;
      padding: 1.5rem;

      .mw-180{
         max-width: 180px;

         @media screen and (max-width: 991px){
            max-width: 140px;
         }
         @media screen and (max-width: 600px){
            max-width: 100px;
         }
      }

      .particle-effect-btn-div{
         padding: 8px 32px;
      }

      @media screen and (max-width: 1068px) {
         max-width: 530px;
      }

      @media screen and (max-width: 991px){
         .font-size-40{
            font-size: 32px;
         }
      }

      @media screen and (max-width: 600px) {
         max-width: 100%;
          .font-size-40{
            font-size: 28px;
         }
         .font-size-20{
            font-size: 16px!important;
         }

      }
      @media screen and (max-width: 400px) {
          .font-size-40{
            font-size: 24px;
         }
      }
   }