#confirm-presence {
   padding: 50px 0;

   @media screen and (max-width: 400px) {
      padding: 70px 0;
   }

   .content-container-col {
      background-image: url("../../../assets/Dubai/presence-modal-bg.png");
      // background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
   }
   .overlay-bck{
      position: absolute;
      height: 100%;
      opacity: 0.7;
      width: 100%;
      background: black;


      @media screen and (max-width: 400px) {
         opacity: 0;
      }
   }
   .custom-pb{
      z-index: 99;

   }

   // .event-background {
   //    position: absolute;
   //    opacity: 0.7;
   //    width: 100%;
   //    object-fit: contain;
   // }
}

.buy-edain-container {
   position: relative;
   //margin-top: 8rem;
}

.buy-edain-container {
   position: relative;
   width: 570px;
   max-width: 600px;
   border-radius: 24px;
   padding: 1.5rem;

   .mw-180 {
      max-width: 180px;

      @media screen and (max-width: 991px) {
         max-width: 140px;
      }
      @media screen and (max-width: 600px) {
         max-width: 100px;
      }
   }

   .particle-effect-btn-div {
      padding: 8px 32px;
   }

   @media screen and (max-width: 1068px) {
      max-width: 530px;
   }

   @media screen and (max-width: 991px) {
      .font-size-40 {
         font-size: 32px;
      }
   }

   @media screen and (max-width: 600px) {
      max-width: 100%;
      .font-size-40 {
         font-size: 28px;
      }
      .font-size-20 {
         font-size: 16px !important;
      }
   }
   @media screen and (max-width: 400px) {
      .font-size-40 {
         font-size: 24px;
      }
   }
}
