@import "./cryptodata-template-main/base/vars";

html,
#root,
body {
   height: 100%;
   width: 100%;
   background-color: $dark-purple;
}

body {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;

   .ct-toast {
      background: $neon-purple;
      color: #fff;
   }
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
