// Import fonts
@import "./breakpoints";

@mixin horizontal-scrollbar {
   &::-webkit-scrollbar {
      height: 0.5rem;
   }

   &::-webkit-scrollbar-track {
      border-radius: 10px;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #7977EF;
   }

   &::-webkit-scrollbar-thumb:hover {
      background-color: darken($color: #7977EF, $amount: 10%);
   }
}

@media only screen and (min-width: $breakpoint-desktop) {
  html {
    font-size: 24px;
  }
  h2 {
    // font-size:56px
    font-size: 2.33em;
  }
  h3 {
    // font-size:48px
    font-size: 2em;
  }
  h4 {
    // font-size:32px
    font-size: 1.3em;
  }
  h5 {
    // font-size: 28px
    font-size: 1.16em;
  }
  h6 {
    // font-size: 24px
    font-size: 1em;
  }
  p {
    //  font-size: 24px
    font-size: 1em;
  }
}

@media (max-width: $breakpoint-desktop) {
  html {
    font-size: 16px;
  }
  h2 {
    // font-size:48px
    font-size: 3em;
  }
  h3 {
    // font-size: 32px
    font-size: 2em;
  }
  h4 {
    // font-size: 28px
    font-size: 1.75em;
  }
  h5 {
    // font-size: 24px
    font-size: 1.5em;
  }
  h6 {
    // font-size: 20px.
    font-size: 1.25em;
  }
  p {
    // font-size: 16px
    font-size: 1em;
  }
}

@media (max-width: $breakpoint-phone) {
  html {
    font-size: 14px;
  }
  h2 {
    // font-size: 32px;
    font-size:2.3em;
  }
  h3 {
    // font-size: 32px;
    font-size:2.3rem;

  }
  h4 {
    // font-size: 28px;
    font-size:2em;

  }
  h5 {
    // font-size: 24px;
    font-size:1.7em ;

  }
  h6 {
    // font-size: 20px;
    font-size: 1.42em;

  }
  p {
    // font-size: 14px;
    font-size: 1em;

  }
}

@font-face {
  font-family: "gotham-book";
  src: url("../font/Gotham-Book.woff") format("woff"),
    url("../font/Gotham-Book.woff2") format("woff2"),
    url("../font/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "gotham-medium";
  src: url("../font/GothamMedium.otf");
}

@font-face {
  font-family: "gotham-light";
  src: url("../font/Gotham-Light.otf");
}
@font-face {
  font-family: "gotham-bold";
  src: url("../font/Gotham-Bold.otf");
}

//Exo 2

@font-face {
  font-family: "exo-extraBold";
  src: url('../font/Exo2/Exo2-ExtraBold.ttf');
}

@font-face {
  font-family: "exo-bold";
  src: url('../font/Exo2/Exo2-Bold.ttf');
}

@font-face {
  font-family: "exo-regular";
  src: url('../font/Exo2/Exo2-Regular.ttf');
}

@font-face {
  font-family: "exo-medium";
  src: url('../font/Exo2/Exo2-Medium.ttf');
}

//Open Sans
@font-face {
  font-family: "openSans-regular";
  src: url('../font/OpenSans/OpenSans-Regular.ttf');
}

// Font size
h1 {
  display: none;
}
.App {
  font-family: gotham-book;
}

// Classes to set fonts
//Exo 2
.exo-extraBold {
  font-family: exo-extraBold !important ;
}
.exo-bold {
  font-family: exo-bold !important ;
}
.exo-regular {
  font-family: exo-regular !important ;
}
.exo-medium {
  font-family: exo-medium !important ;
}

//Open Sans
.openSans-regular {
  font-family: openSans-regular !important ;
}

.gotham-book {
  font-family: gotham-book !important ;
}
.gotham-bold {
  font-family: gotham-bold !important ;
}
.gotham-light {
  font-family: gotham-light !important ;
}
.gotham-medium {
  font-family: gotham-medium !important ;
}

.font-size-64{
  font-size: 60px;
}

.font-size-14{
  font-size: 14px;
}

.font-size-16{
  font-size: 16px!important;
}
.font-size-18{
  font-size: 18px!important;
}
.font-size-20{
  font-size: 20px!important;
}

.font-size-24{
  font-size: 24px;
}

.font-size-28{
  font-size: 28px;
}

.font-size-32{
  font-size: 32px;
}

.font-size-48{
  font-size: 48px;
}

@media screen and (max-width: 767px) {
  .font-size-48 {
    font-size: 32px;
  }
}

@media screen and (max-width: 576px) {
  .font-size-48 {
    font-size: 24px;
  }
}

@media screen and (max-width: 576px) {
  .font-size-20 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 576px) {
  .font-size-24 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 576px) {
  .font-size-18 {
    font-size: 16px !important;
  }
}

.gap-1 {
  gap: 1rem;
}

// colors

.color-neon-green {
   color: #26DE81;
}

.red-text {
   color: $red-text !important;
}

.yellow-color {
   color: $yellow-color !important;
}

.label-grey-color{
  color: $label-grey!important;
}

.blur-bg {
   @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(20px) saturate(170%) contrast(80%) brightness(100%);
      -moz-backdrop-filter: blur(20px) saturate(170%) contrast(80%) brightness(100%);
      -webkit-backdrop-filter: blur(20px) saturate(170%) contrast(80%) brightness(100%);
   }

   @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: rgba(34, 31, 25, 0.9);
   }
}
