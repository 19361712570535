@import "./cryptodata-template-main/components/index";
@import "./cryptodata-template-main/components/containers";
@import "./cryptodata-template-main/base/vars";
@import "./cryptodata-template-main/base/helpers";

.app {
   width: 100%;
   position: relative;
   scroll-behavior: smooth;

   @keyframes scrollAnimation {
      0% {
         transform: translateY(0);
      }

      100% {
         transform: translateY(20px);
      }
   }

   .scroll-mouse {
      position: fixed;
      bottom: 30px;
      left: 48%;
      transform: translate(-50%, -50%);
      z-index: 2000;
      animation: scrollAnimation 3s infinite ease-in;

      @media screen and (max-width: 500px) {
         width: 18px;
         height: auto;
      }
   }

   .separator-line {
      width: 100% !important;
      max-width: 500px !important;
      height: auto;
      margin-bottom: 1rem;

      @media screen and (min-width: 992px) {
         width: 100% !important;
         max-width: 100% !important;
      }
   }

   .info-wrapper {
      @media screen and (max-width: 767px) {
         width: 100% !important;
         display: flex !important;
         justify-content: center !important;
      }

      .info-container {
         @media screen and (max-width: 767px) {
            width: 100% !important;
            max-width: 500px !important;
         }
      }
   }

   .info-wrapper-tech,
   .info-wrapper-comp {
      @media screen and (max-width: 991px) {
         width: 100% !important;
         display: flex !important;
         justify-content: center !important;
      }

      .info-container-tech,
      .info-container-comp {
         @media screen and (max-width: 991px) {
            width: 100% !important;
            max-width: 500px !important;
         }
      }
   }

   .safari-center {
      align-content: center !important;
   }
   .safari-img-center {
      vertical-align: middle !important;
   }

   // cookies banner START
   .cookie-container {
      background-color: $dark-purple !important;
      border-top: 1px solid $light-purple;
      color: #fff !important;
      align-items: center;
   }
   .cookie-content {
      margin: 49px !important;
   }
   #rcc-confirm-button {
      background-color: $neon-purple !important;
      color: #fff !important;
      border: none !important;
      border-radius: 5px !important;
      padding-left: 2em !important;
      padding-right: 2em !important;
      outline: none !important;
   }
   // cookies banner END
}
.overlay-cookie {
   background-color: rgba(0, 0, 0, 0.8) !important;
}

.custom-mobile-size {
   font-size: 32px !important;
}

.custom-particle-item {
   background: none !important;
   border: none !important;
   color: #ff2a6d !important;
   margin-right: 0 !important;
}

@media screen and (max-width: 767px) {
   .custom-mobile-size {
      font-size: 24px !important;
   }
}

.particle-effect-btn-div {
   background: #bd9262;
   color: #000000;
   padding: 0.5rem 0;
   border: 1px solid #bd9262;
   border-radius: 5px;
   cursor: pointer;
   width: 250px;
   margin-right: 1.5rem;
   text-align: center;
}

.custom-pb {
   padding-bottom: 2rem !important;
}

.white-color {
   color: #fff;
}

@media screen and (max-height: 1200px) {
   .home-page {
      .sidebar-navigation {
         height: 950px;
         .binding-line {
            height: 35px;
         }
      }
   }
}

@media screen and (max-width: 1600px) {
   .content-container-col {
      padding-left: 30px !important;
      padding-right: 30px !important;
   }
}

@media screen and (max-width: 1460px) {
   .font-size-64 {
      font-size: 56px !important;
   }
}

@media screen and (max-width: 1400px) {
   .primary-btn-edain {
      width: 200px !important;
   }

   .outline-btn-edain {
      width: 200px !important;
   }

   .particle-effect-btn-div {
      width: 200px !important;
   }
}

@media screen and (max-width: 1320px) {
   .font-size-64 {
      font-size: 52px !important;
   }
}

@media screen and (max-width: 1300px) {
   .font-size-20 {
      font-size: 16px !important;
   }
}

@media screen and (max-width: 1250px) {
   .font-size-64 {
      font-size: 45px !important;
   }
}

@media screen and (max-width: 1110px) {
   .primary-btn-edain {
      width: 180px !important;
   }

   .outline-btn-edain {
      width: 180px !important;
   }

   .particle-effect-btn-div {
      width: 180px !important;
   }
}

@media screen and (max-width: 1080px) {
   .font-size-64 {
      font-size: 41px !important;
   }
}

@media screen and (max-width: 991px) {
   .content-container-col {
      padding-left: 15px !important;
      padding-right: 15px !important;
   }

   .font-size-64 {
      font-size: 32px !important;
   }

   .align-items-center-responsive {
      align-items: center !important;
   }
   .align-items-start-responsive {
      align-items: flex-start !important;
   }

   .justify-content-start-responsive {
      justify-content: flex-start !important;
   }

   .justify-content-center-responsive {
      justify-content: center !important;
   }

   .text-center-responsive {
      text-align: center !important;
   }

   .custom-pb-responsive {
      padding-bottom: 6rem;
   }

   .primary-btn-edain {
      width: 200px !important;
   }

   .outline-btn-edain {
      width: 200px !important;
   }

   .particle-effect-btn-div {
      width: 200px !important;
   }

   .home-page {
      padding-top: 70px;
   }
}

@media screen and (max-width: 767px) {
   .font-size-20 {
      font-size: 18px !important;
   }

   .custom-pb-responsive {
      padding-bottom: 4rem;
   }
}

@media screen and (max-width: 500px) {
   .font-size-64 {
      font-size: 27px !important;
   }
   .custom-pb-responsive {
      padding-bottom: 3rem;
   }
}

@media screen and (max-width: 450px) {
   .edain-buttons {
      .font-size-20 {
         font-size: 14px !important;
      }
      .particle-effect-btn-div,
      .btn-outline-primary,
      .primary-btn-edain {
         width: 150px !important;
      }
   }
   .custom-pb-responsive {
      padding-bottom: 1.5rem;
   }
}

@media screen and (max-width: 370px) {
   .edain-buttons {
      flex-direction: column;

      .particle-effect-btn-div,
      .primary-btn-edain {
         margin-right: 0 !important;
      }
      .btn-outline-primary {
         margin-top: 1.5rem;
      }

      .font-size-20 {
         font-size: 14px !important;
      }

      .styles_canvas__1krxf {
         width: 100%;
      }
   }
}

input:-webkit-autofill {
   -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out" !important;
   -webkit-transition-delay: 9999s !important;
}

input[type="radio"] {
   display: none;
}

input[type="radio"]:disabled + label,
input[type="radio"]:disabled + label:before {
   opacity: 0.5;
   cursor: unset;
}

input[type="radio"] + label:before {
   margin-right: 0.5rem;
   width: 15px;
   height: 15px;
   border-radius: 15px;
   top: -1px;
   left: -1px;
   position: relative;
   background-color: #000000;
   content: "";
   display: inline-block;
   visibility: visible;
   cursor: pointer;

   @media screen and (max-width: 1068px) {
      width: 12px;
      height: 12px;
      top: 0;
   }

   @media screen and (max-width: 991px) {
      width: 10px;
      height: 10px;
      top: 1px;
   }
}

input[type="radio"]:checked + label:before {
   width: 15px;
   height: 15px;
   border-radius: 15px;
   top: -1px;
   left: -1px;
   position: relative;
   background-color: #FFFFFF;
   box-shadow: 0px 0px 12px #FFFFFF;
   content: "";
   display: inline-block;
   visibility: visible;
   cursor: pointer;

   @media screen and (max-width: 1068px) {
      width: 12px;
      height: 12px;
      top: 0;
   }

   @media screen and (max-width: 991px) {
      top: 1px;
      width: 10px;
      height: 10px;
   }
}

.terms-label {
   font-size: 0.7rem !important;
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   .check-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
   }

   .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #FFFFFF;
      border-radius: 5px;

      &::after {
         content: "";
         position: absolute;
         display: none;

         left: 8px;
         top: 2px;
         width: 6px;
         height: 13px;
         border: solid #000000;
         border-width: 0 3px 3px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
   }

   .terms-checkmark {
      @media screen and (max-width: 1068px) {
         top: -4px;
      }
   }

   .check-input:checked ~ .checkmark {
      background-color: #FFFFFF;
   }

   .check-input:checked ~ .checkmark:after {
      display: block;
   }

   .terms-link {
      color: #bd9262;
   }
}

@media screen and (max-width: 767px) {
   .terms-label {
      padding-left: 25px;
      .checkmark {
         top: 9px;
         width: 13px;
         height: 13px;

         &::after {
            left: 4px;
            top: 1px;
            width: 5px;
            height: 9px;
         }
      }
   }
}

@media screen and (max-width: 400px) {
   .terms-label {
      span,
      a {
         font-size: 14px !important;
      }
   }
}