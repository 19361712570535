// Breakpoints
@import "./breakpoints";

// Colors
$color-primary: #1cb495 !default;
$color-primary-light: #5defd2 !default;
$color-secondary: #1a82c3 !default;
$color-success: $color-primary !default;
// $color-success: #67c23a !default;
$color-warning: #eb9e05 !default;
$color-danger: #fa5555 !default;
$color-info: #878d99 !default;
$color-white: #fff !default;
$color-lightgray: #d3d3d3 !default;

// c8 colors
$light-purple: #000000 !default;
$lighter-purple: #000000 !default;
$dark-purple: #000000 !default;
$neon-green: #02ffe9 !default;
$neon-purple: #000000 !default;
$counter-bg-color: #000000 !default;
$counter-border-color: #000000 !default;

// Social colors

$color-instagram: #e4405f;
$color-twitter: #55acee;
$color-pinterest: #bd081c;
$color-facebook: #3b5999;
$color-youtube: #cd201f;

$label-grey: #c8c8c8 !default;
$red-text: #ff2a6d !default;
$yellow-color: #FED330 !default;