/* Addtional  */
.container {
  color: white;
  // background-color: salmon;
}
/* End additional */
.hero-page {
  background-position: center; // might change this for minor adjustment to top or bottom
  background-size: cover;
  background-image: url("../test-images/christmas-hero.jpg");
}
/* Container full screen*/
.container-fullscreen {
  max-height: 1447px;
  /* Kraken website max-height 1680 */
  /* Awwwards.com height:1411px */
}

@media (min-height: 767px) {
  .container-fullscreen {
    height: 100vh;
    max-height: 1447px;
    min-height: 44vw;
  }
}
@media (max-height: 767px) {
  .container-fullscreen {
    height: 800px !important;
  }
  .video-page {
    height: 800px !important;
  }
}

/* Breakpoints for container width  */
@media (min-width: 2800px) {
  .container-fullscreen {
    // width: 2560px;
    max-width: 100%;
  }
}

@media (max-width: 3248px) {
  .container-fullscreen {
    //   width: 2200px;
    max-width: 100%;
  }
}

/* @media (max-width: 1650px) {
       .container {
         width: 1128px;
         max-width: 100%;
       }
     } */

/* Breakpoints for end container width  */

.text-center-absolute {
  position: absolute;
  top: 50%;
  width: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Video page-fullscreen

.video-page {
  color: white;
  height: 100vh;
  overflow: hidden;
  max-height: 1447px;
  min-height: 41vw;
}
#myvideo {
  position: absolute;
  z-index: 0;
  transform: translate(-50%, -50%);
  left: 50%;
  object-fit: cover;
  top: 50%;
  height: 100%;
  width: 177.77777778vh;
  min-width: 100%;
  min-height: 56.25vw;
}

//Content Container
.content-section {
  height: 1000px; //for demonstration
}
.content-container {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background-color: salmon;
}

.content-container {
  padding: 0 15px;
  width: 2560px;
  max-width: 100%;
  //just for demonstration -remove im practice
  /* Kraken website max-height 1680 */
  /* Awwwards.com height:1411px */
}
.content-container-col {
  margin-left: auto;
  margin-right: auto;
  width: 1600px!important;
  padding: 0 15px;
  max-width: 100%!important;
  height: 100%;
}

// .content-container-col {
//   background-color: sandybrown;
// }

.navigation-container {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 1800px !important;
  // padding: 0 15px !important;
  max-width: 100% !important;
  height: 100% !important;
}
