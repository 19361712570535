.error-404 {
   background-color: black;
   min-height: 100vh;
   height: 100%;

   .absolute-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   .img-404-container img {
      width: 600px;
      max-width: 100%;
   }

   .cryptodata-btn {
      background-color: #bd9262;
      color: #000;
      border-color: #bd9262;
      border-radius: 60px;
      font-family: gotham-book !important;
      padding: 0.56rem 0;
      font-size: 18px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 134px;

      &:hover,
      &:active,
      &:focus {
         background-color: #deb88d !important;
         border-color: #deb88d !important;
      }
   }

   .homepage-button {
      width: 216px;
      max-width: 100%;
      border-color: #bd9262;
      color: #bd9262;
      box-shadow: none !important;
      border-radius: 60px;
      font-family: gotham-book !important;
      padding: 0.56rem 0;
      font-size: 18px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus,
      &:active {
         background-color: #deb88d !important;
         border-color: #deb88d !important;
         box-shadow: none !important;
         color: #000 !important;
      }
   }

   .error-buttons {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      justify-content: space-evenly;
   }
}

@media screen and (max-width: 900px) {
   .error-buttons {
      flex-direction: column;
      padding-top: 1rem;
   }
}
