
@import "../../cryptodata-template-main/base/vars";

.social-media-links-wrapper {
    height: 80px;
    position: fixed;
    z-index: 100;
    bottom: 0;


    .social-media-links {
        // width: 275px;
        background-color: transparent;
    }

    .static-icons {
        font-size: 1.2rem;
        color: $color-white;
        cursor: pointer;
        padding: 8px;
        width: 40px;
        height: 40px;
        border: 1px solid rgba(121, 119, 239, 0.3);
        border-radius: 4px;
        margin-right: .25rem;
        @media screen and (max-width: 1150px){
            width: 35px;
            height: 35px;
        }
    }

    .scroll-icon-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}