@import "../../../../cryptodata-template-main/base/_vars.scss";
@import "../../../../cryptodata-template-main/base/_helpers.scss";


#error-container {
    min-height: 700px;
    padding-top: 180px;
    display: flex;
    align-items: center;


    .error-box{
        max-width: 570px;
    }
    
    #error-box {
       position: relative;
       background-color: transparent;
       // background-image: url("../../../../../assets/PNGs/buy-edain.png");
       // background-position: center;
       // background-size: cover;
       // background-repeat: no-repeat;
    
       @media screen and (max-width: 1250px) {
          padding-top: 0 !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
       }
    }
}

@media screen and (max-width: 1068px) {
   #error-box {
      .particle-effect-btn-div {
         width: 200px;
      }
   }
}